<template>
  <v-row justify="start">
    <v-dialog v-model="dialog" scrollable persistent max-width="800px">
      <v-card class="radius-card" v-if="agenda">
        <v-toolbar dense flat>
          <span class="headline">Agenda</span>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="px-2">
          <v-container>
            <v-img
              v-if="agenda.data.poster"
              :src="`${env}/agenda/${agenda.data.poster}`"
              class="img-fit"
            ></v-img>
            <v-col>
              <v-row>
                <v-col cols="12" md="5">Judul</v-col>
                <v-col cols="12" md="7">{{ agenda.data.judul }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">Deskripsi</v-col>
                <v-col cols="12" md="7">{{ agenda.data.deskripsi }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">Lokasi</v-col>
                <v-col cols="12" md="7">{{ agenda.data.lokasi }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">Kategori</v-col>
                <v-col cols="12" md="7">{{ agenda.data.nama_kategori }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">
                  <p>Waktu</p>
                </v-col>

                <v-col cols="12" md="7">
                  <v-row>
                    <v-col cols="12" md="6">
                      <p class="ma-0">waktu mulai</p>
                      <p>
                        {{ $date(agenda.data.start_date).format("DD-MM-YYYY") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="ma-0">waktu selesai</p>
                      <p>
                        {{ $date(agenda.data.end_date).format("DD-MM-YYYY") }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">Fasilitas</v-col>
                <v-col cols="12" md="7">{{ agenda.data.fasilitas }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">Pemateri</v-col>
                <v-col cols="12" md="7">{{ agenda.data.pemateri }}</v-col>
              </v-row>
            </v-col>
            <div v-if="loading">
              <Waiting />
            </div>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import Waiting from "../../_base/Modal/waiting";
export default {
  name: "viewAgenda",

  props: ["dialog", "data"],
  computed: {
    ...mapState({
      listCategory: state => state.library.category,
      env: state => state.API_URL,
      id: state => state.id,
      agenda: state => state.agenda.agendaDetail
    })
  },
  components: {
    Waiting
  },
  data() {
    return {
      pic: null,
      dp: null,
      title: "",
      article: "",
      category: "Psikologi Klinis",
      dialogEmpty: false,
      opt: [
        { state: "public", abbr: "public" },
        { state: "private", abbr: "private" }
      ],
      loading: false,
      isUpdate: false
    };
  },
  mounted() {
    this.getCategory();
    this.$store.dispatch("agenda/AgendaById", this.data.id);
  },
  methods: {
    getCategory() {
      this.$store.dispatch("library/listCategory").then(data => {
        console.log(data);
      });
    },
    getPic(e) {
      const file = e.target.files[0];
      const fr = new FileReader();
      fr.onload = f => {
        this.dp = f.target.result;
      };
      fr.readAsDataURL(file);
      this.pic = file;
    },
    updateItem() {
      this.loading = true;
      const vm = this;
      const id = vm.item.id;
      let data = new FormData();
      data.append("judul", vm.item.judul);
      data.append("isi", vm.item.isi);
      data.append("nama_kategori", vm.item.nama_kategori);
      data.append("visible", vm.item.visible);
      if (vm.pic) {
        data.append("objImage", vm.pic);
      }
      vm.$store
        .dispatch("library/updateArticle", { data: data, id: id })
        .then(data => {
          this.loading = false;
          vm.$emit("close");
          vm.$emit("refetch");
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            timer: 3000,
            toast: true
          });
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        });
    },

    deleteItem(item) {
      //delete  function
      console.log(item.id);
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          //if ok then hit api delete item
          this.$store
            .dispatch("agenda/deleteAgenda", { id: item.id })
            .then(() => {
              this.$emit("close");
              this.$emit("fetch");
              Swal.fire({
                title: "Deleted",
                icon: "success",
                showConfirmButton: false,
                timer: 1000,
                position: "top",
                toast: true
              });
            });
        }
      });
    }
  }
};
</script>

<style scoped>
.temp-cover {
  width: 100%;
  height: 200px;
  border: 1px #000 dotted;
}
</style>
